<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-input
        outlined
        dense
        label="Cari Siswa/NISN"
        v-model="searchTerm"
        @input="getshow"
      >
        <template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>
      <q-markup-table
        flat
        bordered
        separator="horizontal"
        class="stickyTable bg-grey-4"
        style="min-height:calc(100vh - 270px)"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr>
            <th>no</th>
            <th>nama</th>
            <th style="width:150px"></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(val, i) in filteredSiswa" :key="i">
            <td>{{ (pagination.current - 1) * 10 + (i + 1) }}</td>
            <td>{{ val.nama }}</td>
            <td class="row justify-between">
              <q-btn
                flat
                color="primary"
                icon="info"
                @click="
                  selsiswa = val;
                  detail = true;
                "
              ></q-btn>
              <q-btn
                flat
                color="negative"
                icon="delete_forever"
                @click="
                  selsiswa = val;
                  confirm = true;
                "
              ></q-btn>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-actions align="between">
        <q-btn flat label="back" @click="$router.go(-1)"></q-btn>
        <q-pagination
          v-model="pagination.current"
          :max="pagination.max"
          input
          input-class="text-orange-10"
          @input="getshow"
        />
      </q-card-actions>
    </q-card>
    <q-dialog v-model="confirm" v-if="selsiswa">
      <q-card>
        <q-card-section>
          Tulis "{{ selsiswa.nama }}" tanpa tanda kutip untuk me-reset password
          {{ selsiswa.nama }}
          <q-input outlined dense v-model="keyword"></q-input>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="close" v-close-popup></q-btn>
          <q-btn
            unelevated
            label="Hapus"
            :disable="keyword.toLowerCase() != selsiswa.nama.toLowerCase()"
            color="negative"
            @click="hapus()"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="detail" v-if="selsiswa">
      <q-card>
        <q-separator></q-separator>
        <a class="text-grey-9 text-h6 text-bold q-ml-md">Data Siswa</a>
        <q-markup-table>
          <tr>
            <td>Nama Siswa</td>
            <td>:</td>
            <td>{{ nullfilter(selsiswa.nama) }}</td>
          </tr>
          <tr>
            <td>Nama Musyrif</td>
            <td>:</td>
            <td>{{ nullfilter(selsiswa.nama_musyrif) }}</td>
          </tr>
          <tr>
            <td>Kontak Musyrif</td>
            <td>:</td>
            <td>{{ nullfilter(selsiswa.kontak_musyrif) }}</td>
          </tr>
          <tr>
            <td>Nama Kamar</td>
            <td>:</td>
            <td>{{ nullfilter(selsiswa.nama_kamar) }}</td>
          </tr>
          <tr>
            <td>Ketua Kamar</td>
            <td>:</td>
            <td>{{ nullfilter(selsiswa.nama_ketua) }}</td>
          </tr>
        </q-markup-table>

        <q-separator></q-separator>
        <a class="text-grey-9 text-h6 text-bold q-ml-md">Data Keluarga</a>
        <q-markup-table>
          <tr>
            <td>Nama Ayah</td>
            <td>:</td>
            <td>{{ nullfilter(selsiswa.nama_ayah) }}</td>
          </tr>
          <tr>
            <td>Kontak Ayah</td>
            <td>:</td>
            <td>{{ nullfilter(selsiswa.kontak_ayah) }}</td>
          </tr>
          <tr>
            <td>Nama Ibu</td>
            <td>:</td>
            <td>{{ nullfilter(selsiswa.nama_ibu) }}</td>
          </tr>
          <tr>
            <td>Kontak Ibu</td>
            <td>:</td>
            <td>{{ nullfilter(selsiswa.kontak_ibu) }}</td>
          </tr>
        </q-markup-table>

        <q-card-actions align="right">
          <q-btn flat label="close" color="primary" v-close-popup></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detail: false,
      searchTerm: "",

      confirm: false,
      keyword: "",

      listSiswa: [],
      filteredSiswa: [],
      selsiswa: null,

      pagination: {
        current: 1,
        max: 99,
        limit: 10,
      },
    };
  },
  async mounted() {
    await this.getsiswa();
  },
  methods: {
    nullfilter(data) {
      if (data == null || data == "") {
        return "-";
      } else {
        return data;
      }
    },
    async getsiswa() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/siswa/list/getinactive/${this.$route.params.jenjang}/${this.$route.params.tingkat}`
      );
      this.listSiswa = resp.data;
      this.$q.loading.hide();
      this.getshow();
    },
    async hapus() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http.delete(`/siswa/list/deleteinactive/${this.selsiswa.id}`);
      this.getsiswa();
      this.$q.loading.hide();
      this.selsiswa = null;
      this.keyword = "";
    },
    getshow() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let siswa = JSON.parse(JSON.stringify(this.listSiswa));

      if (this.searchTerm != "") {
        siswa = siswa.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }

      let show = [];
      for (let i = 0; i < 10; i++) {
        if (siswa[(this.pagination.current - 1) * 10 + i]) {
          show.push(siswa[(this.pagination.current - 1) * 10 + i]);
        }
      }
      this.pagination.max = Math.ceil(siswa.length / 10);
      this.filteredSiswa = show;
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
